@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

h1 {
  font-weight: bold;
}

p {
  font-family: 'Cormorant Garamond', serif;
}

#root {
  overflow: scroll;
}

.App {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  height: 95vh;
  width: 100%;
  padding: 0px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#intro {
  background-color:white;
  color: black;
}

#intro .btn-primary {
  width: 30%;
  height: 40px;
  background-color: white;
  color: darkgray;
  border: 1px solid white;

}

#intro .btn-primary:hover {
  color: black;
}

#intro .btn-primary:focus {
  box-shadow: none;
}

#intro .btn-primary:nth-child(n+1) {
  margin-left: 20px;
}

#nav {
  background-color: white;
  color: black;
  margin: 0px !important;
  height: 3rem;
}

#nav button {
  float: right;
  background-color: rgba(0, 0, 0, 0);
  border: 0px solid black
}

#nav button:first-child {
  margin-left: auto
}

#nav a {
  color: darkgray;
  text-decoration: none;
}

#nav a:hover {
  color:black
}

iframe {
  height: 88vh;
  margin-top: 20px;
  border: 0px;

}

.card-text a, .card-title a {
  color: black
}

#scroll {
  overflow-x: scroll;
  overflow-y: auto;
  white-space: nowrap;
  height: 90vh;
  @include for-phone-only() {
    height: 85vh;
  }
}

.card {
  overflow: scroll;
  @include for-desktop-up {
    width: 40%;
  }
  @include for-phone-only() {
    font-size: .75rem;
    width: 80%;
  }
  vertical-align: top;
  margin-right: 10px;
  height: 95%;
  display: inline-block;
  white-space: pre-wrap;
  .card-body {
    padding: .5rem;
    a {
      text-decoration: none;
      color: darkgray
    }
    a:hover {
      color: black
    }
  }
}

#scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}

#scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

#about {
  background-color: rgba(255, 255, 255, .9);
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  width: 80%;
  #profile-pic {
    width: 20%;
    margin-top: 4%;
    border-radius: .5rem;
    @include for-phone-only {
      width: 50%;
    }
  }
  #bio {
    border-top: 1px solid darkgray;
    padding: 3%;
    margin: 1%;
  }
  a {
    margin-top: 50px;
    color: darkgray;
    text-decoration: none;
  }
  a:hover {
    color:black
  }
  #links {
    padding-top: 50px;
    img {
      width: 40px;
    }
    :last-child {
      margin-left: 15px
    }
  }
}